// Font Sizes
$font-size-anchor: 11px;
$font-size-button: 12px;
$font-size-regular: 15px;
$font-size-header: 20px;

//Font Weights
$font-weight-bold: 700;
$font-weight-regular: 500;

// Fonts
@font-face {
    font-family: LucidaGrande;
    src: url('../../public/assets/LucidaGrande.woff');
}

@font-face {
    font-family: Mikado;
    src: url('../../public/assets/MikadoRegular.otf') format('opentype');
}

@font-face {
    font-family: MikadoBold;
    src: url('../../public/assets/MikadoBold.otf') format('opentype');
}

// Urls
$arrow-url-black: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.24265 9.48529L2 5.24265L6.24265 1.00001' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");            
$arrow-url-white: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.24265 9.48529L2 5.24265L6.24265 1.00001' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");            

.typography {
    // General
    h1, h2, h3, h4 {
        font-family: MikadoBold;
        font-weight: $font-weight-bold;
    }

    //Desktop
    h1 {
        font-size: 48px;
        line-height: 80px;
    }

    h2 {
        font-size: 40px;
        line-height: 58px;
    }

    h3 {
        font-size: 24px;
        line-height: 56px;
    }

    h4 {
        font-size: 20px;
        line-height: 26px;
    }

    a {
        font-family: LucidaGrande;
        font-size: 13px;
        line-height: 32px;
        font-weight: $font-weight-regular;
        text-decoration: none;

        &.large {
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .text {
        font-family: 'LucidaGrande';
        font-weight: $font-weight-regular;
        line-height: 32px;
        font-size: 15px;

        &.large {
            font-size: 16px;
        }

        &.mini {
            font-size: 13px;
        }
    }

    //Landscape Tablet
    @media only screen and (min-device-width : 768px) 
    and (max-device-width : 1280px) 
    and (orientation : landscape) {
        h1 {
            font-size: 40px;
            line-height: 56px;
        }
    
        h2 {
            font-size: 32px;
            line-height: 50px;
        }
    
        h3 {
            font-size: 20px;
            line-height: 32px;
        }
    
        h4, .h4 {
            font-size: 18px;
            line-height: 32px;
        }

        a {
            line-height: 26px;

            &.large {
                font-size: 12px;
                line-height: 32px;
            }
        }

        .text {
            line-height: 26px;
            font-size: 16px;

            &.mini {
                font-size: 13px;
            }
        }
    }

    //Mobile Devices
    @media only screen and (max-width: 768px) {
        h1, h2, h3, h4 {
        }

        h1 {
            font-size: 20px;
            line-height: 27px;
        }
    
        h2, h3, h4 {
            font-size: 16px;
            line-height: 22px;
        }

        a {
            line-height: 26px;

            &.large {
                font-size: 14px;
                line-height: 32px;
            }
        }

        .text {
            line-height: 26px;
            font-size: 15px;

            &.mini {
                font-size: 13px;
            }
        }
    }
}

.chat-heading,
.chat-text {
    i, span, strong, b, em, a {
        padding-right: 6px;
    }

    a {
        padding-left: 6px;
        padding-right: 6px;
        word-wrap: break-word;
        color: $color-primary-2;
        font-size: inherit ;
        font-family: Mikadobold;
        text-decoration: underline;
    }
}
