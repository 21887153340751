// Breakpoints
$breakpoint-l: 1024px;
$breakpoint-sm: 768px;
$breakpoint-xs: 576px;

// Colours - Default
$color-black: #000000;
$color-white: #FFFFFF;
$color-light-blue: #DBECED;
$color-light-purp: #C6B6D6;
$color-light-blue-shadow: rgba(155,131,180,0.75);
$color-silver: #EFEFEF;
$color-slate: #9F9F9F;
$color-required: #CE4B4B;

// Colours - Primary
$color-primary: #EFE4DD;
$color-primary-1 : #EFE4DD;
$color-primary-2: #4F2579;
// $color-primary-3:#FFDE3E; // legacy color decom20230309 due to incompatibility with color compression
$color-primary-3:#ffe802;
$color-primary-4: #FFDD00;
$color-primary-5: #D4F1F2;

$color-primary-hover: #EFE4DD;
$color-primary-2-hover: #725194;
$color-primary-3-hover:#FFE400;

// Colours - Secondary
$color-secondary: #27B9C0;
$color-secondary-2: #725194;
$color-secondary-3: #727272;
$color-secondary-4: #F4F4F4;

$color-secondary-hover: #55d4da;
$color-secondary-2-hover: #725194;

// Colours - Results
$color-results-1:#7FC68D;
$color-results-2:#FFCF36;
$color-results-3:#F9A948;
$color-results-4:#E78080;
$color-results-5:#CE4B4B;

// Colours - Tab
$color-tab-primary: $color-silver;
$color-tab-secondary: $color-slate;

// Font Sizes
$font-size-anchor: 11px;
$font-size-button: 12px;
$font-size-regular: 15px;
$font-size-header: 20px;

//Font Weights
$font-weight-bold: 700;

// Urls
$arrow-url-black: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.958984 8.31224L7.14617 2.12506L13.3334 8.31224' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");            
$arrow-url-white: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.958984 8.31224L7.14617 2.12506L13.3334 8.31224' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");            

// Fixed heights
$header-height: 132px;