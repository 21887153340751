.component-accordian {
    width: 100%;

    .accordion-header {
        border-radius: 10px;
    }

    .accordion-item {
        background: transparent;
    }

    .accordion-button {
        box-shadow: unset;
        border: unset;
        color: unset;
        background-color: inherit;
        border-radius: 10px !important;
        flex-direction: column;
        font-size: 16px!important;
        padding: 1rem 3rem;
        @media screen and (min-width: $breakpoint-sm) {
            padding:0rem 0rem;
        }

        &::after {
            position: absolute;
            right: 20px;
            top: 22px;
            background-image: url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.919433 1L7.41943 8L13.9194 1" stroke="black" stroke-width="2"/></svg>');
            background-size: 1rem;
        }

        @include text-header;
    }

    .accordion-button:not(.collapsed) {
        background-color: inherit;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        &::after {
            top: 13px;
            background-image: url('data:image/svg+xml,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.919433 1L7.41943 8L13.9194 1" stroke="black" stroke-width="2"/></svg>');
            background-size: 1rem;
        }
    }

    .accordion-body {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-top: unset;

        @include text-general;
    }

    ul {
        margin-bottom: 0;
        
        li {
            color: black;
            
            a {
                color: black;
                margin-right: 8px;
                text-decoration: underline;
            }
        }
    }
}