:root {
  // --low-offset: 1000;
  // --medLow-offset: 1000;
  // --medium-offset: 1000;
  // --medHigh-offset: 1000;
  // --high-offset: 1000;

  --lowPercentage: 50;

  /* NOTE: do not use SASS vars here */
  // --low-color: #7FC68D; //$color-results-1;
  // --medLow-color: #FFCF36; //$color-results-2;
  // --medium-color: #F9A948; //$color-results-3;
  // --medHigh-color: #E78080; //$color-results-4;
  // --high-color: #CE4B4B; //$color-results-4; - also using the medHigh color to match design

  /* for future reference
    $color-results-1:#7FC68D;
    $color-results-2:#FFCF36;
    $color-results-3:#F9A948;
    $color-results-4:#E78080;
    $color-results-5:#CE4B4B;
    */

  --arrow-end-deg: 360deg;
  --arrow-animation-time: 3s;
}

.component-risk-chart {
  padding: 20px;
  overflow: hidden;
  border-radius: 0px;
  @media only screen and (max-width: $breakpoint-l) {
    border-radius: 10px;
  }
  width: 100%;
  margin-bottom: 36px;

  .header {
    margin-top: 20%;
    font-family: "MikadoBold";
  }

  .indicator-wrapper {
    position: relative;
    margin-bottom: 10px;

    .outer-risk-indicator {
      width: 80%;
      position: relative;
    }

    .indicator-ellipse {
      position: absolute;
      top: 10%;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 99;

      .arrow-wrapper {
        height: 100%;
        width: 100%;
        transform: rotate(10deg);
        position: absolute;
        padding-bottom: 100%;
        z-index: 999;
        transition: transform 2.5s;

        // animation-iteration-count: 1;
        // animation: move-arrow var(--arrow-animation-time) normal forwards;

        svg {
          transform: rotate(90deg);
          right: 46%;
          top: 45.5%;
          position: absolute;
        }
        &.circle-beige {
          circle {
            fill: #efe3de;
          }
        }
      }

      //LINES TO POSITION ARROW CENTER
      // .arrow-wrapper:before {
      //     content: '';
      //     position: absolute;
      //     top: 50%;
      //     left: 0;
      //     border-top: 1px solid black;
      //     background: black;
      //     width: 100%;
      //     transform: translateY(-50%);
      //   }

      // .arrow-wrapper:after {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: 50%;
      //     border-left: 1px solid black;
      //     background: black;
      //     height: 100%;
      //     transform: translateY(-50%);
      // }
    }
  }

  .loader {
    width: 100%;
    min-height: 150px;
    position: relative;

    .sliders {
      padding-bottom: 100%;

      svg {
        width: 100%;
        fill: none;
        transform: scale(-1) rotate(334deg) translateX(50%) translateY(10%);
      }
    }

    .low,
    .medLow,
    .medium,
    .medHigh,
    .high {
      position: absolute;
    }

    .low {
      z-index: 5;
    }

    .medLow {
      z-index: 4;
    }

    .medium {
      z-index: 3;
    }

    .medHigh {
      z-index: 2;
    }

    .high {
      z-index: 1;
    }

    &.animate {
      .one,
      .stroke-low {
        animation-iteration-count: 1;
        animation: load-low 1.5s normal forwards;
      }

      .stroke-medLow {
        animation-iteration-count: 1;
        animation: load-medLow 1.5s normal forwards;
        animation-delay: 0.1s;
      }

      .two,
      .stroke-medium {
        animation-iteration-count: 1;
        animation: load-medium 1.5s normal forwards;
        animation-delay: 0.1s;
      }

      .stroke-medHigh {
        animation-iteration-count: 1;
        animation: load-medHigh 1.5s normal forwards;
        animation-delay: 0.1s;
      }

      .three,
      .stroke-high {
        animation-iteration-count: 1;
        animation: load-high 1.5s normal forwards;
        animation-delay: 0.2s;
      }
    }

    .load {
      stroke-width: 15px;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      stroke-linecap: round;
      position: absolute;
      transition: stroke-dashoffset 1.5s;

      &.one,
      &.stroke-low {
        stroke: #7fc68d;
        z-index: 5;
      }

      &.stroke-medLow {
        stroke: #ffcf36;
        z-index: 4;
      }

      &.two,
      &.stroke-medium {
        stroke: #f9a948;
        z-index: 3;
      }

      &.stroke-medHigh {
        stroke: #e78080;
        z-index: 2;
      }

      &.three,
      &.stroke-high {
        stroke: #ce4b4b;
        z-index: 1;
      }

      // $color-results-1:#7FC68D;
      // $color-results-2:#FFCF36;
      // $color-results-3:#F9A948;
      // $color-results-4:#E78080;
      // $color-results-5:#CE4B4B;
    }

    @keyframes load-low {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: var(--low-offset);
      }
    }

    @keyframes load-medLow {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: var(--medLow-offset);
      }
    }

    @keyframes load-medium {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: var(--medium-offset);
      }
    }

    @keyframes load-medHigh {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: var(--medHigh-offset);
      }
    }

    @keyframes load-high {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: var(--high-offset);
      }
    }
  }
}

@keyframes move-arrow {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(var(--arrow-end-deg));
  }
}
