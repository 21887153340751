$hc-pading-left-x:(
    padding-left-8 : 8px,
    padding-left-16 : 16px,
    padding-left-32 : 32px,
    padding-left-40 : 40px,
    padding-left-48 : 48px,
    padding-left-56 : 56px,
    padding-left-64 : 64px,
    padding-left-80 : 80px,
    padding-left-88 : 88px,
    padding-left-96 : 96px,
    padding-left-104 : 104px,
    padding-left-112 : 112px,
    padding-left-120 : 120px,
    padding-left-144 : 144px,
    padding-left-160 : 160px,
);

@each $padding, $px in $hc-pading-left-x{

    .#{$padding}{
        padding-left: $px;
    }
}

$hc-pading-right-x:(
    padding-right-8 : 8px,
    padding-right-16 : 16px,
    padding-right-32 : 32px,
    padding-right-40 : 40px,
    padding-right-48 : 48px,
    padding-right-56 : 56px,
    padding-right-64 : 64px,
    padding-right-80 : 80px,
    padding-right-88 : 88px,
    padding-right-96 : 96px,
    padding-right-104 : 104px,
    padding-right-112 : 112px,
    padding-right-120 : 120px,
    padding-right-144 : 144px,
    padding-right-160 : 160px,
);

@each $padding, $px in $hc-pading-right-x{

    .#{$padding}{
        padding-right: $px;
    }
}

$hc-pading-top-x:(
    padding-top-8 : 8px,
    padding-top-16 : 16px,
    padding-top-32 : 32px,
    padding-top-40 : 40px,
    padding-top-48 : 48px,
    padding-top-56 : 56px,
    padding-top-64 : 64px,
    padding-top-80 : 80px,
    padding-top-88 : 88px,
    padding-top-96 : 96px,
    padding-top-104 : 104px,
    padding-top-112 : 112px,
    padding-top-120 : 120px,
    padding-top-144 : 144px,
    padding-top-160 : 160px,
);

@each $padding, $px in $hc-pading-top-x{

    .#{$padding}{
        padding-top: $px;
    }
}

$hc-pading-bottom-x:(
    padding-bottom-8 : 8px,
    padding-bottom-16 : 16px,
    padding-bottom-32 : 32px,
    padding-bottom-40 : 40px,
    padding-bottom-48 : 48px,
    padding-bottom-56 : 56px,
    padding-bottom-64 : 64px,
    padding-bottom-80 : 80px,
    padding-bottom-88 : 88px,
    padding-bottom-96 : 96px,
    padding-bottom-104 : 104px,
    padding-bottom-112 : 112px,
    padding-bottom-120 : 120px,
    padding-bottom-144 : 144px,
    padding-bottom-160 : 160px,
);

@each $padding, $px in $hc-pading-bottom-x{

    .#{$padding}{
        padding-bottom: $px;
    }
}

$hc-pading-vertical-x:(
    padding-vertical-8 : 8px,
    padding-vertical-16 : 16px,
    padding-vertical-32 : 32px,
    padding-vertical-40 : 40px,
    padding-vertical-48 : 48px,
    padding-vertical-56 : 56px,
    padding-vertical-64 : 64px,
    padding-vertical-80 : 80px,
    padding-vertical-88 : 88px,
    padding-vertical-96 : 96px,
    padding-vertical-104 : 104px,
    padding-vertical-112 : 112px,
    padding-vertical-120 : 120px,
    padding-vertical-144 : 144px,
    padding-vertical-160 : 160px,
);

@each $padding, $px in $hc-pading-vertical-x{

    .#{$padding}{
        padding-bottom: $px;
        padding-top: $px;
    }
}

$hc-pading-horizontal-x:(
    padding-horizontal-8 : 8px,
    padding-horizontal-16 : 16px,
    padding-horizontal-32 : 32px,
    padding-horizontal-40 : 40px,
    padding-horizontal-48 : 48px,
    padding-horizontal-56 : 56px,
    padding-horizontal-64 : 64px,
    padding-horizontal-80 : 80px,
    padding-horizontal-88 : 88px,
    padding-horizontal-96 : 96px,
    padding-horizontal-104 : 104px,
    padding-horizontal-112 : 112px,
    padding-horizontal-120 : 120px,
    padding-horizontal-144 : 144px,
    padding-horizontal-160 : 160px,
);

@each $padding, $px in $hc-pading-horizontal-x{

    .#{$padding}{
        padding-left: $px;
        padding-right: $px;
    }

}