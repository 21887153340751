.component-slider {
    overflow: hidden;

    .slider-indicators {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 40px;
        margin-bottom: 20px;

        li {
            height: 8px;
            width: 8px;
            border-radius: 100%;
            background-color:$color-primary;

            &.active {
                background-color:$color-primary-2;
            }
        }
    }

    .slider-inner {
        white-space: nowrap;
        transition: transform 0.2s;

        .slider-item {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 200px;

            svg {
                vertical-align: unset;
            }
            
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
