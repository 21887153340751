.partial-desktop-header {
	background-color: white;
    width: 100%;
    height: 90px;
    padding-left: 75px;

    .mh4l-logo {
        width: 115px;
        height: 100%;
    }

    &.hide {
        display: none;
    }

    @media screen and (max-width: $breakpoint-l) {
        display: none;
        height: 0;
    }
}