.component-cvd-score {
    display: flex;
    justify-content: left;
    align-items: left;
    margin: 32px 0 0 60px;

    @media screen and (min-width: $breakpoint-sm) {
        margin-left: 80px
    }

    &.component-dynamic-input {
        margin-top: 16px;
    }

    + .component-yes-no {
        margin-bottom: 16px !important;
    }

    span {
        margin: 0 10px;
    }

    .seperator {
        font-size: 22px;
        font-weight: bold;
    }
    
    input {
        border-radius: 6px;
        width: 100%;
        margin-bottom: 9px;
        outline: none;
        border: none;
        background: transparent;
        border: 1.5px solid $color-slate;
        padding: 6px 18px;
        font-size: 13px;
        line-height: 18px;

        &:focus {
            border-color: $color-primary-2 !important;
        }

        &.required {
            border: 2px solid $color-required;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $color-required;
            }
            
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $color-required;
            }
            
            &::-ms-input-placeholder { /* Microsoft Edge */
                color:$color-required;
            }
        }
        
        max-width: 306px;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}