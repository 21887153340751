// Adding styles for the wordpress embed site
main.content {
    .page-content {
        width: 100% !important;
        max-width: 100% !important;
    }
}

:host {
    all: initial;
}

#mh4l-root:host {
    all: initial;
}

#mh4l-root.embed .partial-desktop-header {
    display: none !important;
}

.App {
    // max-width: 1100px;
    // margin: 0 auto 100px;
    font-family: LucidaGrande;
    position: relative;
    overflow: hidden;
    height: 100vh;

    @media screen and (max-width: 550px) {
        height: 145vh;
    }

    &.scrollable-y {
        overflow: auto;
    }
}

.main-content {
    background-color: white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: absolute;
    top: 120px;
    right: 0;

    &.quiz-active {
        top: 225px;
    }
}

a {
    font-weight: $font-weight-bold;
    color: $color-primary;
    font-size: $font-size-anchor;
    text-align: center;
    line-height: 13px;
    cursor: pointer;

    &:hover {
        color: $color-primary-2-hover!important;
    }
}

.text-block {
    text-align: center;
}

.bg-white {
    background-color: $color-white;
}

.bg-pattern {
    // background: url('../assets/MH4L_Background_Pattern.svg');
    background-color: $color-secondary;
}

.bg-white {
    background-color: white;
    &.header {
        font-size: $font-size-header;
        font-family: MikadoBold;
        font-weight: $font-weight-bold;
        line-height: 25px;
    }
}

.bg-primary {
    background-color: $color-primary !important;
}

.bg-primary-2 {
    background-color: $color-primary-2;
}

.bg-primary-3 {
    background-color: $color-primary-3;
}

.bg-primary-4 {
    background-color: $color-primary-4;
}

.bg-primary-5 {
    background-color: $color-primary-5;
}

.bg-secondary {
    background-color: $color-secondary;
}

.bg-secondary-2 {
    background-color: $color-secondary-2;
}

.bg-light-blue {
    background-color: $color-light-blue;
}

.bg-light-purp {
    background-color: $color-light-purp;
}

@mixin text-header {
    text-align: center;
    font-size: $font-size-header;
    font-family: MikadoBold;
    font-weight: $font-weight-bold;
}

@mixin text-general {
    text-align: center;
    font-size: $font-size-regular;
}

.mh4l-logo {
    // background-image: url("../assets/logo.svg");        
    background-repeat: no-repeat;
    background-position: center;

    &.black {
        background-image: url("../assets/logo-black.svg");        
    }
}

.text-white {
    color: $color-white;
} 

.container-primary {
    position: relative;
    overflow-y: hidden;
    // height: calc(100vh - 90px); //height of header

    height: 100%;

    @media screen and (max-width: $breakpoint-sm) {
        // height: 100vh;
    }

    &.scrollable {
        height: 100vh;
        min-height: 100%;
    }
}

.margin-user-chat-input {
    margin: 0 60px 0 auto;
}

@mixin margin-user-chat-input-left {
    margin-left: 60px;

    @media screen and (min-width: $breakpoint-sm) {
        margin-left: 80px
    }
}

.last-button {
    margin: 32px 0 0 60px;

    &.Single-Select, &.Multi-Select, &.InformationOnly, &.dynamic-input-button, &.Single-Select-Dropdown, &.Yes\/No, &.BloodPressure {
        // margin-left: auto;
        // margin-right: auto;
    }

    @media screen and (min-width: $breakpoint-sm) {
        margin-left: 80px
    }

    &.component-dynamic-input {
        margin-top: 16px;
    }
}

.modal {
    background: rgba(0,0,0,0.1);
    // display: block !important;
}


.loading {
    display: inline-block;
    width: 80px;
    height: 80px;

    &.small {
        width: 40px;
        height: 40px;

        &:after {
            width: 32px;
            height: 32px;
            border-width: 3px;
        }
    }

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $color-primary;
        border-color: $color-primary-2 transparent $color-primary-2 transparent;
        animation: loading-animation 1.2s linear infinite;
    }
}

@keyframes loading-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text {
    &.text-error,
    &.text-success, 
    &.text-loading,
    &.text-validate {
        text-align: left;
        margin-left: 80px;
        min-height: 30px;
        line-height: 24px;

        @media screen and (max-width: $breakpoint-sm) {
            margin-left: 60px;
        }

        &.text-error-results {
            margin-left: 0;
            height: unset;
        }
    }

    &.text-error {
        color: $color-results-4 !important;
    }

    &.text-success {
        color: $color-results-1 !important;
    }

    &.text-loading {
        color: $color-primary-2 !important;
    }

    &.text-loading {
        .loading {
            width: 20px !important;
            height: 20px !important;

            &:after {
                width: 16px !important;
                height: 16px !important;
                border-width: 3px !important;
            }
        }
    }

    &.hide {display: none;}
}

.last-button {
    margin-bottom: 16px;
}