.toggle-results-btn {
    width: min-content;
    position: absolute;
    top: 25px;
    right: 0;
    padding: 0px 12px !important;
    height: 25px !important;
    z-index: 10;
}

.results-box {
     position: absolute;
    top: 0;
    background-color: white;
    z-index: 6;
    width: 100%;   
    height: 100%;
    overflow-y: auto;
    padding-bottom: 130px;
    overflow-x: hidden;

    @media only screen and (min-width: 1040px) {
        .component-footer {
            display: none;
        }
    }

    padding: 50px 0px 0px 0px;

    @media only screen and (max-width: $breakpoint-l) {
        padding: 50px 24px 164px
    }
}

.component-results-screen {
    @media only screen and (min-width: $breakpoint-l) {
        max-width: 100%;
        padding-bottom: 100px;
    }
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .risk-heading {
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 32px;
        border-bottom: 3px solid #EFE4DD;
        padding-bottom: 10px;
        font-family: 'MikadoBold';
    }

    .lower-risk-section {
        background-color: #F6F6F6;
        padding: 27px 24px;
        width: unset;

        &.section-heading {
            margin-top: 26px;

            h2 {
                border-bottom: 3px solid $color-secondary !important;
            }
        }

        @media only screen and (max-width: $breakpoint-l) {
            margin-left: -24px;
            margin-right: -24px;
        }
    }
}