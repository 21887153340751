
.component-results-footer {
    padding: 80px 20px;
    background-color: $color-secondary-4;

    &.mobile-footer {
        display: none;
    }

    &.desktop-footer {
        display: block;
    }

    @media screen and (max-width: $breakpoint-l) {
        margin-left: -24px;
        margin-right: -24px;

        &.desktop-footer {
            display: none;
        }

        &.mobile-footer {
            display: block;
        }
    }

    @media screen and (min-width: 600px) {
        &.mobile-footer {
            margin-left: calc(((100vw - 600px) / 2) * -1); // 600px = max width of .component-results-screen
            margin-right: calc(((100vw - 600px) / 2) * -1);            
        }
    }

    .qld-logos {
        :first-child {
            margin-right: 36px;;
        }

        margin-bottom: 32px;
    }

    h4 {
        margin: unset;
    }

    .alliance-text {
        font-size: 32px;
        line-height: 43.1px;
        color: $color-secondary;
        margin: 48px 0;
        font-family: 'MikadoBold';


        @media only screen and (min-width: $breakpoint-l) {
            &:before,
            &:after {
                background-color: $color-secondary;
                content: "";
                display: inline-block;
                height: 3px;
                vertical-align: middle;
                margin-left: 30px;
                margin-right: 30px;

                width: 15%;
            }
        }
    }

    .footer-nav {
        margin-top: 50px;

        ul {
            padding-left: unset;
            li {
                list-style: none;
                display: inline-block;
                font-size: 13px;
    
                a {
                    text-decoration: underline;
                    padding: 0px 10px;
                }

    
                margin-right: 8px;
            }
        }
    }

    .partner-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        .column-wrapper {
            display: flex;
        }
    }

    .acknowledgement {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        margin-top: 40px;
    }

    @media only screen and (max-width: $breakpoint-l) {
        padding: 34px 20px;

        .qld-logos {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto !important;
            }
        }

        .alliance-text {
            font-size: 24px;
            line-height: 32.33px;
            margin: 32px 0;
        }

        .partner-logo {
            .column-wrapper {
                flex-direction: column;
            }
        }

        .footer-nav {
            margin-top: 32px;
        }

        .acknowledgement {
            margin-top: 32px;
            font-size: 12px;
            line-height: 18px;
        }
    }
}
