.component-social-share {
    .suggested-link {
        margin-top: 22px;
        
        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: $color-primary-2;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }

    .share-button {
        margin-top: 32px;
        padding-left: 25px;
        width: max-content;
        padding-right: 25px;
    }

    .social-share-buttons {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 30px;

        .fb {
            margin-right: 14px;
        }
    }
}