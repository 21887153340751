.component-result-tab {
    position: relative;

    @media only screen and (max-width: $breakpoint-l) {
        margin-left: -40px;
        margin-right: -40px;    
        padding:50px 24px 0px;
    }

    @media only screen and (min-width: $breakpoint-sm) {
        .heading {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 32px;
        }
    }

    .heading {
        margin-bottom: 24px;
    }

    .tab-content {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .tab-content-upper-section {
            background-color: $color-primary-5;
        }

        .tab-content-padding.container + .tab-content-padding.container {
            padding-top: 0 !important;
        }

        .tab-content-padding {
            padding: 12px 40px;

            &.container,
            &.section-heading {
                padding: 32px 40px !important;
                // margin: 32px 0;
            }

            &.container {
                :not(:last-of-type(p)) {
                    margin-bottom: 48px; 
                    font-family: Mikadobold;
                }

                p a {
                    color: $color-primary-2;
                    font-size: 15px;
                    line-height: 19.5px;
                    font-weight: 500;
                    text-decoration: underline;
                    transition: opacity 250ms;
                    cursor: pointer;
                }
            }

            &.section-heading {
                .risk-heading {
                    margin-bottom: 0 !important;
                }

                p {
                    margin-bottom: 48px; 
                    font-family: Mikadobold;
                }
            }

            &.background-grey {

            }
        }
    }

    nav {
        background-color: $color-silver;

        
        &.stick {
            position: sticky;
            top: -50px;
            left: 0;
            right: 0;
            z-index: 10;
        }

        @media only screen and (min-width: $breakpoint-l) {
            &.stick {
                left: 44%;
                top: -50px;
            }
        }

        .nav-tabs .nav-link.active{
            color: white;
            border-color: #dee2e6 #dee2e6 #fff;
            font-size: 11px;
            font-weight: 700;
            font-family: 'Mikadobold';
            background-color: $color-secondary;
            text-decoration: none;
        }
    
        .nav-tabs .nav-link {
            border: 0px solid transparent!important;
            margin: 4px;
            font-size: 11px;
            font-weight: 700;
            color: $color-secondary-3;
            font-family: 'Mikado';
            border-radius: 20px;
            background-color: #DADADA;
            text-decoration: underline;
        }
    
        .nav-tabs-outer {
            background-color: $color-silver;
            padding: 8px 40px;
            display: inline-block;

        }
    
        .nav-tabs {
            border-radius: 20px; 
            background-color: #DADADA;
            flex-wrap: nowrap!important;
        }
    
        .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
            opacity: 0.8;
            transition: 0.3s;
        }
    }

    p {
        font-size: 15px;
        line-height: 19.5px;
        font-weight: 500;
    }

    .container-link {
        padding: 0 !important;
        word-break: break-all;

        a {
            color: #212529;
            font-size: 15px;
            line-height: 19.5px;
            font-weight: 500;
            text-decoration: underline;
            transition: opacity 250ms;
            cursor: pointer;

            &:after {
                content: '',
                // background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKZSURBVHgBlVQ7ixNRFL53Hpv3PHYNJoWQIgFJp6mFoIIP1kcTG/+AhVhYaSGkEEXdHyKk8S+IWAti0i7YhDR5kEehO3P9zsy5s+OYuOyFjzP39d3vPOYIcf7xDVApHANSnHcopSRfNGzbvgp7HfiUIjZ4PyK3tnA8BLz0gpSxEBB+b7VaP0aj0StMHwBfgGtMSuRhlkyyIu1SFp+Bi8BrPvMOOOTvAr0JmOA4JST0ej2TD90E9oBcu92OLF/UhEelUqkGewP4CTjNZjPHpDIdM5OJ6NItoASUU1YTfgAuA4+AS8VisQ7rA8VOp2MnpP1+32D5Bb546DjOPuw+x/ctr79nhV+B50QK1DQpc0giE8PhMMqsOE0cuWGw+sfAC8MwSOHRer0WTEaPnJTL5QA24LnQVjIZqXR4kSrgAnCAjF+BfYrLVVZV4zg+4zMeq9xjYbFSxEKmEqZfC13XDeHucaVS+bhardJ7gtX9An6TYiQ54PWkvkS9Xpe+7+t1he9gsViE8/k8NE3zRMQ1GLDVNRk0Go1obTAYqDjnKnHfzrj/ErgL3AFu8/c94L6Ii34OPNFuc6KTYbH7YjKZiM1mI6bTKcXrjTh7LP63GSmF+/SqK+LgUyn5rJzqtIRwuLx2QGcQb5/3dIKSkUzG47FKryM5Bu9TWZmz2UyXXZpANxSRJVXdblch4OkDxnK5JGIitDQxeYXSiqoATUbBO+2p+kdptVpV+N0om6HneTrTIYgjAvxdEg8QBEor2kNVBLlcLhRbOlPsA/5XqCVFeTxA/7lLMeQ4upm5w2cKHM+/OtO2UFjcbfIiLpciJ7DA8wLClCcwoZU0kB1DJ8KkbsOxJGvzXMNir4xdhDIbhmgRnZ5c0h1/x9jp8x+sTNUSEkkJEQAAAABJRU5ErkJggg==');
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
    .suggested-link {
        margin-top: 22px;
        
        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: $color-primary-2;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
}