.component-progress-bar {
    border: 2px solid $color-secondary;
    border-radius: 25px;
    height: 26px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    margin: 20px auto 0;
    max-width: 360px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $breakpoint-l) {
        border-color: $color-primary-3;
    }

    .inner-progress {
        height: 16px;
        border-radius: 25px;
        background-color: $color-secondary;
        min-width: 5%;
        transition: width 0.2s;
        position: relative;
        z-index: 20;
        overflow: hidden;

        @media screen and (max-width: $breakpoint-l) {
            background-color: $color-primary-3;
        }

        .shadow {
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            color: $color-white;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold !important;
        }
    }

    .header {
        position: absolute;
        z-index: 10;
        left: 0;
        width: 100%;
        text-align: center;
        color: $color-secondary;
        font-size: 12px;
        line-height: 16px;
        font-family: MikadoBold;

        @media screen and (max-width: $breakpoint-l) {
            color: white;
        }
    }
}