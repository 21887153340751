.component-header {
    background: transparent;
    padding: 20px 24px 15px;
    transition: padding 0.2s;
    height: auto;

    @media screen and (max-width: $breakpoint-sm) {
        // padding-top: 60px;
    }

    @media screen and (min-width: $breakpoint-sm) {
        padding-left: 60px;
        padding-right: 60px;
    }

    
    @media screen and (min-width: $breakpoint-l) {
        &.results {
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
            z-index: 4;
            box-sizing: border-box;
            box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 40%);            
        }
    }

    @media screen and (max-width: $breakpoint-l) {
        background: $color-secondary;
    }

    .mh4l-logo {
        background-image: url("../assets/mh4l-logo-white-text-yellow-thumb.svg");        
        height: 90px;
        align-items: center;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center;
        width: 200px;
        display: none;
        
        @media screen and (max-width: $breakpoint-l) {
            display: inline-block;
        }

        &.inProgress {
            height: 45px;
        }

        &.results {
            height: 60px;
        }

        &.initial {
            margin-top: 30px;
            margin-bottom: 25px;
            background-size: cover;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;    
            height: 100%;        
        }
    }

    .back-button {
        position: absolute;
        height: 45px;
        display: flex;
        align-items: center;

        @media screen and (min-width: $breakpoint-l) {
            align-items: flex-end;

            .component-button {
                background-color: $color-secondary;
            }
        }
    }
}
