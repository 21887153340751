.component-image {
    margin-bottom: 40px;    
    
    @media screen and (max-width: $breakpoint-sm) {
        margin-bottom: 24px;    
    }

    img {
        height: 100%;
        width: 100%; 
        max-height: 175px;
        object-fit: contain;
    }
}