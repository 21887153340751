.component-yes-no {
    margin-top: 24px;
    max-width: 300px;

    @media (min-width: 420px) {
        margin-left: 60px;
    }

    @media (min-width: $breakpoint-sm) {
        margin-left: 80px;
    }

    @media (min-width: $breakpoint-sm) {
        max-width: 360px;
        // margin-left: unset !important;
        // margin-right: unset !important;

        .component-button.regular.yesno {
            padding: 6px 72px;
        }
    }

    &.component-dynamic-yes-no .button-wrapper {
        // margin-left: 60px;

        @media (min-width: $breakpoint-sm) {
            // margin-left: 80px;
            width: 100%;
        }
    }

    &.include-m-b {
        margin-bottom: 32px;
    }
}
